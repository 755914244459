@import "~bootstrap/scss/bootstrap";

/* You can add global styles to this file, and also import other style files */
html, body {
  background-color: #EEEEEE !important;
  overflow-x: hidden;
}

.text-justify {
  text-align: justify;
  text-justify: inter-word;
}

.font-weight-bold {
  font-weight: bold;
}